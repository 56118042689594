<template>
  <img alt="Ax logo" src="./assets/DarkOrangeLogoBlack.svg" width="300px">
  <a class="top-right-link" href="https://achademix.statuspage.io/">Service Status</a>
  <LandingPage />
</template>

<script>
import LandingPage from "@/components/Landing.vue";

export default {
  name: 'App',
  components: {
    LandingPage
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.top-right-link {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-10%, 50%);
}
</style>
