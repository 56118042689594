<script>
  import axios from 'axios'
  import {nextTick} from "vue";

  export default {
    name: 'LandingPage',
    beforeMount() {
      window.addEventListener('message', function (event) {
        if (this.origin === event.origin) return;
        if (event.data !== 'redirect') return;
        window.location.href = document.getElementById("ifrAhuiliztli").src;
      });
    },
    async mounted() {
      document.title = 'Achademix'
      if(this.$refs.searchInput) this.$refs.searchInput.focus();
      await this.getSchoolInfo(this.$isDev);
    },
    data() {
      return {
        selectedSchool: null,
        username: null,
        password: null,
        schoolUrl: null,
        schoolList: [],
        searchString: ""
      };
    },
    methods: {
      selectSchool(s) {
        this.searchString = null;
        this["selectedSchool"] =  s;
        if (s) nextTick(() => this.$refs.usernameInput.focus());
      },
      selectNext() {
        let refs = Object.keys(this.$refs).filter(s => s.startsWith("_")).filter(s => this.$refs[s] && this.$refs[s].length > 0).sort()
        let nextRef = refs.find(s => s !== undefined);
        if (refs.length > 1) {
          if (nextRef) this.$refs[nextRef][0].focus();
        }else {
          let found = this.schoolList.find(s => `_${s.schoolKey}` === nextRef);
          this.selectSchool(found);
        }
      },
      doLogin() {
        // TODO: Recomiendo aqui validar los inputs y si todo esta bien bloquearlos y continuar. Es decir, hacer las validaciones de input aqui.
        const iframe = document.getElementById("ifrAhuiliztli");
        const params = {
          action: 'redirect-login',
          username: this.username,
          password: this.password
        };
        iframe.contentWindow.postMessage(params, "*");
      },
      getSchoolInfo(all) {
        axios.get(`https://axconn.achademix.info/schools${all ? '?showAll=true': ''}`)
        .then((response) => this.schoolList = response.data)
        .then(() => {
          if (this.$isLocalhost)
            this.schoolList.push({
              instructorPortal: "http://localhost:8081",
              schoolFullName: "Localhost",
              schoolKey: "localhost",
              schoolName: "Localhost",
              aliases: []
            })
        })
        .catch(() => this.schoolList = JSON.parse("[{\"schoolKey\":\"DEV\",\"schoolName\":\"DEV Test\",\"instructorPortal\":\"https://dev.achademix.net\",\"studentPortal\":\"https://dev.axstudent.com\"}]"));
      }
    },
    watch: {
      selectedSchool() {
        this.schoolUrl = this.selectedSchool ? this.selectedSchool.instructorPortal : "";
      }
    },
    computed: {
      foundList() {
        if (!this.searchString && !this.selectedSchool) return [];
        if (this.selectedSchool) return this.schoolList.filter(s => s === this.selectedSchool);
        let search = this.searchString.toLowerCase();
        return this.schoolList.filter(s =>
          s.schoolKey.toLowerCase().includes(search) || //SchoolKey
          s.schoolName.toLowerCase().includes(search) || //ShortName
          s.schoolFullName.toLowerCase().includes(search) || //FullName
          s.aliases.find(a => a.toLowerCase().includes(search))
        ).slice(0,3);
      }
    }
  };
</script>

<template>
  <div class="shadow-lg p-3 mb-5 bg-body rounded mx-auto mt-5" style="max-width: 400px; margin-top: 150px">
    <div class="d-flex flex-column">
      <div class="form-floating mb-3" v-if="!selectedSchool">
        <input ref="searchInput" name="searchInput" type="text" class="form-control" id="searchFloatingInput" placeholder="" v-model="searchString" @keyup.enter="selectNext()">
        <label for="searchFloatingInput">Search your School</label>
      </div>
      <div v-for="(found) in foundList" :key="found.schoolKey" :ref="`_${found.schoolKey}`" tabindex="0" class="d-flex flex-row m-1 mx-2 px-2 align-items-center" style="transition: width 2s;" @click="selectSchool(found)" @keyup.enter="selectSchool(found)">
        <div>
          <img :src="`/schools/${found.schoolKey}.ico`" onerror="this.src='/schools/default.ico'"  width="60px"  alt="logo"/>
        </div>
        <div class="my-auto ms-2 text-start">
            {{found.schoolFullName}}
        </div>
        <button v-if="selectedSchool" type="button" class="btn-close btn-close-custom ms-auto me-1 pe-1" aria-label="Close" @click.stop="selectSchool()"></button>
      </div>
      <div>
        <div class="form-floating mb-3 mt-3" v-if="selectedSchool">
          <input ref="usernameInput" type="text" class="form-control" id="userFloatingInput" placeholder="" v-model="username" @keyup.enter="this.$refs.passwordInput.focus()">
          <label for="userFloatingInput">Username</label>
        </div>
        <div class="form-floating mb-3" v-if="selectedSchool">
          <input ref="passwordInput" type="password" class="form-control" id="passFloatingInput" placeholder="" v-model="password" @keyup.enter="this.$refs.loginButton.focus()">
          <label for="passFloatingInput">Password</label>
        </div>
      </div>
    </div>
    <div class="row mt-1" v-if="selectedSchool">
      <div class="col">
        <input ref="loginButton" class="btn btn-outline-primary" name="login" type="button" @click="doLogin" value="Login"/>
      </div>
    </div>
    <iframe id="ifrAhuiliztli" ref="ifrAhuiliztli" style="display: none;" :src="schoolUrl" v-if="selectedSchool"/>
  </div>
</template>

<style lang="css">

.btn-close-custom {
  filter: invert(0.5) sepia(500) saturate(50) hue-rotate(-20deg);
  vertical-align: middle;
}
.verticalAlign {
  vertical-align: middle;
}

</style>